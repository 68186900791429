import React from 'react';
import { useState } from 'react';
import { useDispatch} from 'react-redux'
import { addGame, findGameCount } from '../features/gamesSlice';
import TextInput from '../components/TextInput'
import Button from 'react-bootstrap/Button';
import '../styling/global.css'

function AddGamesPage() {
  const [category1, setCategory1]  = useState("")
  const [category2, setCategory2]  = useState("")
  const [category3, setCategory3]  = useState("")
  const [category4, setCategory4]  = useState("")

  const [question1, setQuestion1]  = useState("")
  const [question2, setQuestion2]  = useState("")
  const [question3, setQuestion3]  = useState("")
  const [question4, setQuestion4]  = useState("")
  const [question5, setQuestion5]  = useState("")
  const [question6, setQuestion6]  = useState("")
  const [question7, setQuestion7]  = useState("")
  const [question8, setQuestion8]  = useState("")
  const [question9, setQuestion9]  = useState("")
  const [question10, setQuestion10]  = useState("")
  const [question11, setQuestion11]  = useState("")
  const [question12, setQuestion12]  = useState("")
  const [question13, setQuestion13]  = useState("")
  const [question14, setQuestion14]  = useState("")
  const [question15, setQuestion15]  = useState("")
  const [question16, setQuestion16]  = useState("")

  const [answer1, setAnswer1]  = useState("")
  const [answer2, setAnswer2]  = useState("")
  const [answer3, setAnswer3]  = useState("")
  const [answer4, setAnswer4]  = useState("")
  const [answer5, setAnswer5]  = useState("")
  const [answer6, setAnswer6]  = useState("")
  const [answer7, setAnswer7]  = useState("")
  const [answer8, setAnswer8]  = useState("")
  const [answer9, setAnswer9]  = useState("")
  const [answer10, setAnswer10]  = useState("")
  const [answer11, setAnswer11]  = useState("")
  const [answer12, setAnswer12]  = useState("")
  const [answer13, setAnswer13]  = useState("")
  const [answer14, setAnswer14]  = useState("")
  const [answer15, setAnswer15]  = useState("")
  const [answer16, setAnswer16]  = useState("")

  const [feedback, setFeedback] = useState("")

  const dispatch = useDispatch()

  const submitGame = async () => {
    try {
      const totalGames = await dispatch(findGameCount())
      const body = 
      {
        Number: Number(totalGames.payload + 1),
        Categories: 
        [
          category1,
          category2,
          category3,
          category4
        ],
        Questions: 
        [
          question1,
          question2,
          question3,
          question4,
          question5,
          question6,
          question7,
          question8,
          question9,
          question10,
          question12,
          question13,
          question14,
          question15,
          question16,
        ],
        Answers:
        [
          answer1,
          answer2,
          answer3,
          answer4,
          answer5,
          answer6,
          answer7,
          answer8,
          answer9,
          answer10,
          answer11,
          answer12,
          answer13,
          answer14,
          answer15,
          answer16,
        ]
      }
      const response = await dispatch(addGame(body))
      setFeedback('Game Added Successfully!')
    } catch(error) {
      setFeedback('An error occurred. Try again later.')
    }
  }

  const categoryOneOnChange = (e) => {
    setCategory1(e.target.value)
  }
  const categoryTwoOnChange = (e) => {
    setCategory2(e.target.value)
  }
  const categoryThreeOnChange = (e) => {
    setCategory3(e.target.value)
  }
  const categoryFourOnChange = (e) => {
    setCategory4(e.target.value)
  }

  const quesOneOnChange = (e) => {
    setQuestion1(e.target.value)
  }
  const quesTwoOnChange = (e) => {
    setQuestion2(e.target.value)
  }
  const quesThreeOnChange = (e) => {
    setQuestion3(e.target.value)
  }
  const quesFourOnChange = (e) => {
    setQuestion4(e.target.value)
  }
  const quesFiveOnChange = (e) => {
    setQuestion5(e.target.value)
  }
  const quesSixOnChange = (e) => {
    setQuestion6(e.target.value)
  }
  const quesSevenOnChange = (e) => {
    setQuestion7(e.target.value)
  }
  const quesEightOnChange = (e) => {
    setQuestion8(e.target.value)
  }
  const quesNineOnChange = (e) => {
    setQuestion9(e.target.value)
  }
  const quesTenOnChange = (e) => {
    setQuestion10(e.target.value)
  }
  const quesElevenOnChange = (e) => {
    setQuestion11(e.target.value)
  }
  const quesTwelveOnChange = (e) => {
    setQuestion12(e.target.value)
  }
  const quesThirteenOnChange = (e) => {
    setQuestion13(e.target.value)
  }
  const quesFourteenOnChange = (e) => {
    setQuestion14(e.target.value)
  }
  const quesFifteenOnChange = (e) => {
    setQuestion15(e.target.value)
  }
  const quesSixteenOnChange = (e) => {
    setQuestion16(e.target.value)
  }
  const answerOneOnChange = (e) => {
    setAnswer1(e.target.value)
  }
  const answerTwoOnChange = (e) => {
    setAnswer2(e.target.value)
  }
  const answerThreeOnChange = (e) => {
    setAnswer3(e.target.value)
  }
  const answerFourOnChange = (e) => {
    setAnswer4(e.target.value)
  }
  const answerFiveOnChange = (e) => {
    setAnswer5(e.target.value)
  }
  const answerSixOnChange = (e) => {
    setAnswer6(e.target.value)
  }
  const answerSevenOnChange = (e) => {
    setAnswer7(e.target.value)
  }
  const answerEightOnChange = (e) => {
    setAnswer8(e.target.value)
  }
  const answerNineOnChange = (e) => {
    setAnswer9(e.target.value)
  }
  const answerTenOnChange = (e) => {
    setAnswer10(e.target.value)
  }
  const answerElevenOnChange = (e) => {
    setAnswer11(e.target.value)
  }
  const answerTwelveOnChange = (e) => {
    setAnswer12(e.target.value)
  }
  const answerThirteenOnChange = (e) => {
    setAnswer13(e.target.value)
  }
  const answerFourteenOnChange = (e) => {
    setAnswer14(e.target.value)
  }
  const answerFifteenOnChange = (e) => {
    setAnswer15(e.target.value)
  }
  const answerSixteenOnChange = (e) => {
    setAnswer16(e.target.value)
  }



  return (
    <div className='has-default-bg is-column is-centered has-padding-top'>
      <TextInput header={"Category 1"} onChange={categoryOneOnChange} />
      <div className='is-row'>
        <TextInput header={"Question 1"} onChange={quesOneOnChange} />
        <TextInput header={"Question 2"} onChange={quesTwoOnChange} />
        <TextInput header={"Question 3"} onChange={quesThreeOnChange} />
        <TextInput header={"Question 4"} onChange={quesFourOnChange} />
      </div>
      <div className='is-row has-padding-top'>
        <TextInput header={"Answer 1"} onChange={answerOneOnChange} />
        <TextInput header={"Answer 2"} onChange={answerTwoOnChange} />
        <TextInput header={"Answer 3"} onChange={answerThreeOnChange} />
        <TextInput header={"Answer 4"} onChange={answerFourOnChange} />
      </div>

      <div className='spacer'></div>

      <TextInput header={"Category 2"} onChange={categoryTwoOnChange} />
      <div className='is-row'>
        <TextInput header={"Question 1"} onChange={quesFiveOnChange} />
        <TextInput header={"Question 2"} onChange={quesSixOnChange} />
        <TextInput header={"Question 3"} onChange={quesSevenOnChange} />
        <TextInput header={"Question 4"} onChange={quesEightOnChange} />
      </div>
      <div className='is-row has-padding-top'>
        <TextInput header={"Answer 1"} onChange={answerFiveOnChange} />
        <TextInput header={"Answer 2"} onChange={answerSixOnChange} />
        <TextInput header={"Answer 3"} onChange={answerSevenOnChange} />
        <TextInput header={"Answer 4"} onChange={answerEightOnChange} />
      </div>

      <div className='spacer'></div>

      <TextInput header={"Category 3"} onChange={categoryThreeOnChange} />
      <div className='is-row'>
        <TextInput header={"Question 1"} onChange={quesNineOnChange} />
        <TextInput header={"Question 2"} onChange={quesTenOnChange} />
        <TextInput header={"Question 3"} onChange={quesElevenOnChange} />
        <TextInput header={"Question 4"} onChange={quesTwelveOnChange} />
      </div>
      <div className='is-row has-padding-top'>
        <TextInput header={"Answer 1"} onChange={answerNineOnChange} />
        <TextInput header={"Answer 2"} onChange={answerTenOnChange} />
        <TextInput header={"Answer 3"} onChange={answerElevenOnChange} />
        <TextInput header={"Answer 4"} onChange={answerTwelveOnChange} />
      </div>

      <div className='spacer'></div>

      <TextInput header={"Category 4"} onChange={categoryFourOnChange} />
      <div className='is-row'>
        <TextInput header={"Question 1"} onChange={quesThirteenOnChange} />
        <TextInput header={"Question 2"} onChange={quesFourteenOnChange} />
        <TextInput header={"Question 3"} onChange={quesFifteenOnChange} />
        <TextInput header={"Question 4"} onChange={quesSixteenOnChange} />
      </div>
      <div className='is-row has-padding-top'>
        <TextInput header={"Answer 1"} onChange={answerThirteenOnChange} />
        <TextInput header={"Answer 2"} onChange={answerFourteenOnChange} />
        <TextInput header={"Answer 3"} onChange={answerFifteenOnChange} />
        <TextInput header={"Answer 4"} onChange={answerSixteenOnChange} />
      </div>



      <h2 className='has-padding-top'>{feedback}</h2>

      <Button size= 'lg' style={{backgroundColor: '#211951'}} className='main-button-small' onClick={submitGame}>
        <h3 className='button-text'>Add</h3>
      </Button>
      
    </div>
  );
}

export default AddGamesPage;
