import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import image1 from '../assets/saba7o-2.jpg'
import image0 from '../assets/Sc-1.jpg'
import image2 from '../assets/sc-2.jpg'
import '../styling/mainPage.css'

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel style={{maxWidth: '90vw'}}activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <img src={image2} width={'315px'}/>
      <Carousel.Caption>
          <h3 className='button-text'> كارت فاول تكتيكي </h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item >
        <img src={image1} width={'315px'}/>
        <Carousel.Caption>
          <h3 className='button-text'> بوكس ريسك </h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ width: '315px', margin: 'auto' }}>
        <img src={image0} width={'315px'}/>
        <Carousel.Caption>
          <h3 className='button-text'>صباحو ريسك</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;