import Form from 'react-bootstrap/Form';
import '../styling/answersPage.css'

function Input(props) {
  return (
    <div className='input-field'>
      <Form.Label>
         {props.header}
      </Form.Label>
      <Form.Control
      onChange={props.onChange}
        type="number"
      />
      <Form.Text muted>
      {props.instructions}
      </Form.Text>
    </div>
  );
}

export default Input;