import axios from 'axios'

const API_URL = '/api/games/'



const findGameInfo = async(id) => {
    const api = API_URL + '/' + id
    const response = await axios.get(api, {
        withCredentials: true
      });

    return response.data
}

const findGameCount = async() => {
    const api = API_URL
    const response = await axios.get(api, {
        withCredentials: true
      });

    return response.data
}

const addGame = async (data) => {

  const api = API_URL
  const response = await axios.post(api, {
    data: data
  })

}


const gamesServices = {
    findGameInfo,
    findGameCount,
    addGame
}

export default gamesServices