import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import answersServices from './answersServices'


const initialState = {
    answer: 'hola',
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const findAnswer = createAsyncThunk('api/ques/', async (quesNo, thunkAPI) => {
    try {
        return await answersServices.findAnswer(quesNo) 
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
        error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const answersSlice = createSlice({
    name: 'answers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findAnswer.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(findAnswer.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.answer = action.payload; 
            })
            .addCase(findAnswer.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { reset } = answersSlice.actions
export default answersSlice.reducer