import axios from 'axios'

const API_URL = '/api/ques/'



const findAnswer = async(quesNo) => {
    const api = API_URL + quesNo
    const response = await axios.get(api);

    return JSON.stringify(response.data[0].Answer)
}



const answersServices = {
    findAnswer
}

export default answersServices