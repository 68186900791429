import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import '../styling/answersPage.css'
import '../styling/mainPage.css'
import Input from '../components/Input'
import Button from 'react-bootstrap/Button';
import { findOrder } from '../features/orderSlice';

function AuthenticationPage() {
  const [orderNumber, setOrderNumber]  = useState("")
  const [feedback, setFeedback] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submitOrderNumber = async () => {
    try {
      const response = await dispatch(findOrder(orderNumber))
      if(!response.error || response.payload.Order != null) {
        const timestamp = new Date().getTime();
        localStorage.setItem('loggedInOrder', orderNumber)
        localStorage.setItem('loggedInTimeStamp', timestamp);
        navigate('/bonus')
      } else {
        setFeedback("لا يوجد طلب بهذا الرقم")
        localStorage.removeItem('loggedInOrder')
      }
    } catch(error) {
    }
  }

  const onChange = (e) => {
    setOrderNumber(e.target.value)
  }

  useEffect(() => {
    const expiryTime =  6 * 60 * 60 * 1000; //6 hrs

    const storedTimestamp = localStorage.getItem('loggedInTimeStamp');

    if (storedTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - storedTimestamp > expiryTime) {
        localStorage.removeItem('loggedInOrder');
        localStorage.removeItem('loggedInTimeStamp');
      }
    }
    const currOrder = localStorage.getItem('loggedInOrder');
    
    if (currOrder) {
      navigate('/bonus');
    }
  }, []);

  return (
    <div className='main-div-bg'>
      <h3 className='subtext-small'>اكتب رقم الطلب الخاص بك</h3>
      <Input header={" رقم الطلب"} instructions={" اذا كنت لا تعرف رقم الطلب الخاص بك برجاء التواصل معنا عن طريق الفيسبوك أو الإنستجرام"} onChange={onChange} />
      <Button  size= 'lg' style={{backgroundColor: '#211951'}} className='main-button-small' onClick={submitOrderNumber}>
        <h3 className='button-text'>تــــم</h3>
      </Button>
      <div className='answers-div' >
        <h2 className='answer-small' style={{marginInline: '20px', color: '#ad29ea'}}>{feedback}</h2>
      </div>
    </div>
  );
}



export default AuthenticationPage;
