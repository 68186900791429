import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import gamesServices from './gamesServices'


const initialState = {
    order: 0,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const addGame = createAsyncThunk('api/games/', async (body, thunkAPI) => {
    try {
        return await gamesServices.addGame(body) 
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
        error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const findGameInfo = createAsyncThunk('api/games/', async (id, thunkAPI) => {
    try {
        return await gamesServices.findGameInfo(id) 
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
        error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const findGameCount = createAsyncThunk('api/games/', async (id, thunkAPI) => {
    try {
        return await gamesServices.findGameCount() 
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
        error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findGameInfo.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(findGameInfo.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.answer = action.payload; 
            })
            .addCase(findGameInfo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { reset } = gamesSlice.actions
export default gamesSlice.reducer