import Form from 'react-bootstrap/Form';
import '../styling/textInput.css'

function TextInput(props) {
  return (
    <div className='text-input'>
      <Form.Label>
         {props.header}
      </Form.Label>
      <Form.Control
      onChange={props.onChange}
      />
      <Form.Text muted>
      {props.instructions}
      </Form.Text>
    </div>
  );
}

export default TextInput;