import React from 'react';
import { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { findAnswer } from '../features/answersSlice';
import '../styling/answersPage.css'
import '../styling/mainPage.css'
import Input from '../components/Input'
import Button from 'react-bootstrap/Button';

function AnswersPage() {
  const [question, setQuestion]  = useState("")
  const [answer, setAnswer] = useState("")
  const dispatch = useDispatch()

  const status = useSelector((state) => state.status);
  const error = useSelector((state) => state.error);
  

  const submitQuestionNumber = async () => {
    try {
      const response = await dispatch(findAnswer(question))
      if(!response.error) {
        setAnswer(response.payload.slice(1, -1))
      } else {
        setAnswer("رقم السؤال غير صحيح")
      }
    } catch(error) {
    }
  }

  const onChange = (e) => {
    setQuestion(e.target.value)
  }

  return (
    <div className='main-div-bg'>
      <h3 className='subtext-small'>اكتب رقم السؤال</h3>
      <Input header={"رقم السؤال"} instruction={"رقم السؤال بين ١ و ٣٢٠ موجود على أسفل الكارت"} onChange={onChange} />
      <Button  size= 'lg' style={{backgroundColor: '#211951'}} className='main-button-small' onClick={submitQuestionNumber}>
        <h3 className='button-text'>تــــم</h3>
      </Button>
      <div className='answers-div' >
        <h2 className='answer-small' style={{marginInline: '20px', color: '#ad29ea'}}>{answer}</h2>
        <h2 className='subtext-
        \small'>  :الإجابة </h2>
      </div>
    </div>
  );
}



export default AnswersPage;
