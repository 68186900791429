import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../styling/answersPage.css'
import '../styling/mainPage.css'
import DropdownInput from '../components/Dropdown';
import QuestionCard from '../components/QuestionCard';
import {findGameCount, findGameInfo} from '../features/gamesSlice'
import { useState } from 'react';
import {useDispatch} from 'react-redux';
import { useEffect } from 'react';
import {useNavigate} from 'react-router-dom'


function ExtraGamesPage() {

  const [availableGames, setAvailableGames] = useState([]);
  const [categories, setCategories]  = useState(["", "", "", ""])
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const getGameInfo = async (id) => {
    try {
      const response = await dispatch(findGameInfo(id))
      const payload = response.payload
      setCategories(payload.Categories)
      setQuestions(payload.Questions)
      setAnswers(payload.Answers)
    } catch(error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const expiryTime =  6 * 60 * 60 * 1000; //6 hrs

    const storedTimestamp = localStorage.getItem('loggedInTimeStamp');

    if (storedTimestamp) {
      const currentTime = new Date().getTime();
      if (currentTime - storedTimestamp > expiryTime) {
        localStorage.removeItem('loggedInOrder');
        localStorage.removeItem('loggedInTimeStamp');
        navigate('/authentication')
      }
    }
    const currOrder = localStorage.getItem('loggedInOrder');
    
    if (!currOrder || !storedTimestamp) {
      navigate('/authentication');
    }
     const getGamesCount = async () => {
      const response = await dispatch(findGameCount())
      let games = []
      for(let i = 0; i < response.payload; i++){
        games.push(" لعبة #"  + Number(i+1));
      }
      setAvailableGames(games)
    }
    getGamesCount() 
  }, [])
  return (
    <div className='main-div-bg'>
      <DropdownInput loadGame={getGameInfo} title="اختر اللعبة"  options={availableGames} />
      <Container className="grid-container">
      <Row>
          <Col>
           <p className='category-name'> {categories[0]}  </p>
          </Col>
          <Col>
          <p className='category-name'> {categories[1]}  </p>
          </Col>
          <Col>
          <p className='category-name'> {categories[2]}  </p>          
          </Col>
          <Col>
          <p className='category-name'> {categories[3]} </p>          
          </Col>
        </Row>

        <Row>
          <Col>
            <QuestionCard question={questions[0]} answer={answers[0]} category={categories[0]} questionDifficulty={5} />
          </Col>
          <Col>
            <QuestionCard question={questions[4]} answer={answers[4]} category={categories[1]} questionDifficulty={5}/>
          </Col>
          <Col>
            <QuestionCard question={questions[8]} answer={answers[8]} category={categories[2]} questionDifficulty={5}/>
          </Col>
          <Col>
            <QuestionCard question={questions[12]} answer={answers[12]} category={categories[3]} questionDifficulty={5}/>
          </Col>
        </Row>

        <Row>
          <Col>
            <QuestionCard question={questions[1]} answer={answers[1]} category={categories[0]} questionDifficulty={10}/>
          </Col>
          <Col>
            <QuestionCard question={questions[5]} answer={answers[5]} category={categories[1]} questionDifficulty={10}/>
          </Col>
          <Col>
            <QuestionCard question={questions[9]} answer={answers[9]} category={categories[2]} questionDifficulty={10}/>
          </Col>
          <Col>
            <QuestionCard question={questions[13]} answer={answers[13]} category={categories[3]} questionDifficulty={10}/>
          </Col>
        </Row>

        <Row>
          <Col >
            <QuestionCard question={questions[2]} answer={answers[2]} category={categories[0]} questionDifficulty={20}/>
          </Col>
          <Col>
            <QuestionCard question={questions[6]} answer={answers[6]} category={categories[1]} questionDifficulty={20}/>
          </Col>
          <Col>
            <QuestionCard question={questions[10]} answer={answers[10]} category={categories[2]} questionDifficulty={20}/>
          </Col>
          <Col>
            <QuestionCard question={questions[14]} answer={answers[14]} category={categories[3]} questionDifficulty={20}/>
          </Col>
        </Row>

        <Row>
          <Col >
            <QuestionCard question={questions[3]} answer={answers[3]} category={categories[0]} questionDifficulty={40}/>
          </Col>
          <Col>
            <QuestionCard question={questions[7]} answer={answers[7]} category={categories[1]} questionDifficulty={40}/>
          </Col>
          <Col>
            <QuestionCard question={questions[11]} answer={answers[11]} category={categories[2]} questionDifficulty={40}/>
          </Col>
          <Col>
            <QuestionCard question={questions[15]} answer={answers[15]} category={categories[3]} questionDifficulty={40}/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}



export default ExtraGamesPage;
