
import '../styling/questionCard.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';

function QuestionModal(props) {

  const [isAnswerHidden, setAnswerHidden] = useState(true);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        سؤال  {props.category}  بـ  {props.difficulty} نقاط
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4> </h4>
        <p>
          {props.question}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <h3 hidden={isAnswerHidden}>{props.answer}</h3>
        <Button onClick={() =>{setAnswerHidden(false)} }>الإجابة</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QuestionModal;