
import '../styling/questionCard.css'
import { useState } from 'react';
import QuestionModal from './QuestionModal'


function QuestionCard(props) {

 
  const [modalShow, setModalShow] = useState(false);


  return (
    <>
      <div onClick={() => setModalShow(true)} className="cardBox">
        <div className="card">
          <span className="text">{props.questionDifficulty}</span>
        </div>
      </div>

      <QuestionModal 
        question={props.question}
        answer={props.answer}
        difficulty={props.questionDifficulty}
        category={props.category}
        show={modalShow}
        onHide={() => setModalShow(false)} 
      />
    </>

  );
}

export default QuestionCard;