import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import orderServices from './orderServices'


const initialState = {
    order: 0,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

export const findOrder = createAsyncThunk('api/orders/', async (orderNo, thunkAPI) => {
    try {
        return await orderServices.findOrder(orderNo) 
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
        error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(findOrder.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(findOrder.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.answer = action.payload; 
            })
            .addCase(findOrder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { reset } = orderSlice.actions
export default orderSlice.reducer