import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MainPage from './screens/MainPage'
import ErrorPage from './screens/ErrorPage';
import AddGamesPage from './screens/AddGamesPage';
import AnswersPage from './screens/AnswersPage'
import { useEffect, useState } from 'react'
import Header from './components/Header';
import ExtraGamesPage from './screens/ExtraGamesPage';
import AuthenticationPage from './screens/AuthenticationPage';

function App() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetch("https://saba7orisk.onrender.com")
      .then((res) => res.json())
      .then((data) => setMessage(data.message));
  },[]);

  return (
    <>
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='/error-xj&1301fg' element={<ErrorPage />} />
        <Route path='/answers' element={<AnswersPage />} />
        <Route path='/bonus' element={<ExtraGamesPage />} />
        <Route path='/admin/add/f13g01' element={<AddGamesPage />} />
        <Route path='/authentication' element={<AuthenticationPage />} />
      </Routes>
    </Router>
    <ToastContainer />
    </>
  );
}

export default App;
