import React from 'react';
import '../styling/mainPage.css';
import Button from 'react-bootstrap/Button';
import ControlledCarousel from '../components/Carousel';

function MainPage() {
  return (
    <div>
      <div className='titles-div'>
        <h3 className='subtext-small'>صبااحووووو</h3>
        <h1 className='text-large'>الصفحة الرسمية للعبة صباحو ريسك</h1>
        <Button href= "/answers" size= 'lg' style={{backgroundColor: '#211951'}} className='main-button-small'>
          <h3 className='button-text'>يلا ريسك</h3>
        </Button>
      </div>
      <div className='carousel-div'>
        <ControlledCarousel />
      </div>
    </div>
  );
}

export default MainPage;
