import axios from 'axios'

const API_URL = '/api/orders/'



const findOrder = async(quesNo) => {
    const api = API_URL + quesNo
    const response = await axios.get(api);

    return JSON.stringify(response.data.order)
}



const orderServices = {
    findOrder
}

export default orderServices