
import Dropdown from 'react-bootstrap/Dropdown';
import '../styling/extraGames.css'
import { useState } from 'react';


function DropdownInput(props) {

  const [activeGame, setActiveGame]  = useState("الألعاب المتاحة")
  
  const createDropDownOptions = (availabaleGames) => {
    let gamesOptions = []
    for(let i = 0; i < availabaleGames.length; i++) {
      gamesOptions.push(<Dropdown.Item name={Number(i+1)} onClick={onDropDownSelected}>{availabaleGames[i]}</Dropdown.Item>)
    }
    return gamesOptions
  }

  const onDropDownSelected = (e) => {
    setActiveGame(" لعبة #"  + e.target.name)
    props.loadGame(e.target.name)
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" className='dropdownInput'>
        {activeGame}
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdownOptions' >
        {createDropDownOptions(props.options)}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownInput;