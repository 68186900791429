import React from "react";
import '../styling/header.css'
import NavBar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "bootstrap/dist/css/bootstrap.min.css"
import logo from '../assets/Logo-Risk.png'
import '../styling/header.css'


function Header() {
    return (
    <NavBar className="header" expand="lg">
      <Container>
        <NavBar.Brand href="/">
            <img 
                src= {logo} 
                height="40" 
                className="d-inline-block align-top" 
            />
        </NavBar.Brand>
        <NavBar.Toggle className="toggle-button"  aria-controls="basic-navbar-nav">
          <p>__</p>
        </NavBar.Toggle>
        <NavBar.Collapse className="drop-down" id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link style={{color: 'white'}} href="/answers">
                <h3 className='button-text' style={{fontSize: '20px'}}>الإجابات</h3>
              </Nav.Link>
            <Nav.Link  style={{color: 'white'}} href="/authentication">
              <h3 className='button-text' style={{fontSize: '20px'}}>ألعاب إضافية</h3>
              </Nav.Link>
            <NavDropdown className='drop-down-button-text' style={{backgroundColor: '#836FFF', borderRadius: '10px', fontSize: '20px'}} title="تواصل معنا">
              <NavDropdown.Item href="https://instagram.com/foultaktiky">
                <h3 className='button-text'>إنستجرام</h3> 
              </NavDropdown.Item>
              <NavDropdown.Item href="https://facebook.com/foultaktiky">
                <h3 className='button-text'>فيسبوك</h3>
                </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="https://foultaktiky.com">
               <h3 className='button-text'> الموقع </h3> 
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </NavBar.Collapse>
      </Container>
    </NavBar>
    )
}

export default Header;